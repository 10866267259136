import React from "react"
import { Provider } from "react-redux"
import { createStore as reduxCreateStore, applyMiddleware } from "redux"
import { createLogger } from "redux-logger"
import thunkMiddleware from "redux-thunk"
import rootReducer from "./reducers"
import { cookiesMiddleware } from "./middleware"
import storage from "redux-persist/lib/storage"
import { persistReducer, persistStore } from "redux-persist"
import { PersistGate } from "redux-persist/integration/react"

const getMiddleware = () => {
  if (process.env.NODE_ENV === "production") {
    return applyMiddleware(thunkMiddleware, cookiesMiddleware)
  } else {
    return applyMiddleware(thunkMiddleware, cookiesMiddleware, createLogger())
  }
}

const persistConfig = { key: "root", storage }

const persistedReducer = persistReducer(persistConfig, rootReducer)

const createStore = () => reduxCreateStore(persistedReducer, getMiddleware())

const store = createStore()

const persistor = persistStore(store)

export default ({ element }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {element}
    </PersistGate>
  </Provider>
)
