import { VIEW_ABSTRACT } from "../actionTypes"

const initialState = {
  detail: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case VIEW_ABSTRACT:
      return { ...state, detail: action.payload }
    default:
      return state
  }
}
