import ky from "ky"
import Cookies from "universal-cookie"

export const API_ROOT =
  process.env.NODE_ENV === "development"
    ? "http://localhost:7001/api/v1"
    : "https://api.bankjaringandansel.id/api/v1"

let token = null

const api = () => {
  const cookies = new Cookies()
  token = token || cookies.get("jwt")
  return ky.create({
    prefixUrl: API_ROOT,
    headers: { Authorization: token ? `Bearer ${token}` : undefined },
  })
}

export const Users = {
  login: async payload => {
    try {
      return await api().post(`users/login`, { json: payload })
    } catch (e) {
      return null
    }
  },
  register: async payload => {
    try {
      return await api().post(`users/register`, { json: payload })
    } catch (e) {
      return null
    }
  },
  confirm: async () => {
    try {
      return await api().post(`users/confirm`)
    } catch (e) {
      return null
    }
  },
  details: async () => {
    try {
      return await api().get(`users/details`)
    } catch (e) {
      return null
    }
  },
  activate: async payload => {
    try {
      return await api().post(`users/activate`, { json: payload })
    } catch (e) {
      return null
    }
  },
  download: async () => {
    try {
      return await api().get(`users/download`)
    } catch (e) {
      return null
    }
  },
  stats: async () => {
    try {
      return await api().get(`users/stats`)
    } catch (e) {
      return null
    }
  },
  reviewer: async () => {
    try {
      return await api().get(`users/reviewer`)
    } catch (e) {
      return null
    }
  },
}

export const Files = {
  submitted: async () => {
    try {
      return await api().get(`files/submitted`)
    } catch (e) {
      return null
    }
  },
  submitting: async payload => {
    try {
      return payload
      // return await api().post(`files/upload`, { json: payload })
    } catch (e) {
      return null
    }
  },
  details: async () => {
    try {
      return await api().get(`files/details`)
    } catch (e) {
      return null
    }
  },
  assign: async payload => {
    try {
      return await api().post(`files/assign`, { json: payload })
    } catch (e) {
      return null
    }
  },
  review: async file_id => {
    try {
      return await api().get(`files/review/${file_id}`)
    } catch (e) {
      return null
    }
  },
  reviewing: async payload => {
    try {
      return await api().post(`files/review`, { json: payload })
    } catch (e) {
      return null
    }
  },
  status: async payload => {
    try {
      return await api().post(`files/status`, { json: payload })
    } catch (e) {
      return null
    }
  },
}

export const Items = {
  catalog: async () => {
    try {
      return await api().get(`items/catalog`)
    } catch (e) {
      return null
    }
  },
}

export const Purchases = {
  bought: async () => {
    try {
      return await api().get(`purchases/order`)
    } catch (e) {
      return null
    }
  },
  initiate: async payload => {
    try {
      return await api().post(`purchases/order`, { json: payload })
    } catch (e) {
      return null
    }
  },
}

export const setToken = _token => {
  token = _token
}
