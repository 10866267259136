import Cookies from "universal-cookie"
import { LOGIN, LOGOUT } from "./actionTypes"
import { setToken } from "./api"

const cookies = new Cookies()

const cookiesMiddleware = store => next => action => {
  if (action.type === LOGIN) {
    if (!action.error) {
      cookies.set("jwt", action.payload.token)
      setToken(action.payload.token)
    }
  } else if (action.type === LOGOUT) {
    cookies.set("jwt", "")
    setToken(null)
  }

  next(action)
}

export { cookiesMiddleware }
