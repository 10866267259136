import { REGISTER, LOGIN, LOGOUT, CONFIRM } from "../actionTypes"

const initialState = {
  token: null,
  form: null,
  profile: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER:
      const form = action.payload.form
      Object.keys(form).forEach(key => {
        if (!form[key].length) delete form[key]
      })
      return { ...state, form }
    case LOGIN:
      const payload = action.payload
      const { token, ...profile } = payload
      return { ...state, token, profile }
    case LOGOUT:
      return { ...state, token: null, profile: null }
    case CONFIRM:
      const confirmed = true
      return { ...state, profile: { ...state.profile, confirmed } }
    default:
      return state
  }
}
