import { SAVE_CATALOG, ADD_TO_CART } from "../actionTypes"

const initialState = {
  catalog: null,
  cart: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SAVE_CATALOG:
      const { participant, day1, day2, workshop } = action.payload.items
      const catalog = [...participant, ...day1, ...day2, ...workshop]
      return { ...state, catalog }
    case ADD_TO_CART:
      return { ...state, cart: action.payload }
    default:
      return state
  }
}
